@tailwind base;
@tailwind components;
@tailwind utilities;

input.appearance-none[type="number"]::-webkit-inner-spin-button,
input.appearance-none[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.appearance-none[type="number"] {
  -moz-appearance: textfield;
}